import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import styled from "styled-components"
import { val }  from '../components/cssvalue'
import { media } from '../components/style-utils';


const Contact = styled.section`
  &.section {
    background: ${val.WHITE};
    border-top: 2px solid ${val.BG2};
    > .-inner {
      max-width: ${val.width_pc};
      margin: 0 auto;
      padding: 117px 0 90px;
      ${media.sp`
        padding: 6vw 0 10vw;
      `}
      > .title {
        background: ${val.BG2};
        top: 39px;
        left: -39px;
        position: absolute;
        margin: 0;
        ${media.sp`
          top: 9vw;
          left: -40px;
        `}
      }
      > .box {
        text-align: center;
      }
    }
    .-p {
      font-size: 14px;
      letter-spacing: .145em;
      ${media.sp`
        font-size: 4vw;
        line-height: 1.7;
      `}
    }
  }
`;
const ButtonSubmit = styled.button`
  font-size: 15px;
  padding: 9px 17px 8px 15px;
  margin-left: -6px;
  .icon {
    height: 21px;
    width: 21px;
    vertical-align: -5px;
    margin-left: 10px;
  }
`;
const Form = styled.form`
  width: 500px;
  text-align: left;
  margin: 46px auto 0;
  font-size: 13px;
  ${media.sp`
    width: 100%;
    margin-top: 10vw;
    font-size: 3.5vw;
  `}
  .form-radio {
    margin-left: 65px;
    ${media.sp`
      margin-left: 12vw;
    `}
    > label {
      position: relative;
      cursor: pointer;
      margin-left: 34px;
      ${media.sp`
        margin-left: 7vw;
      `}
      input {
        opacity: 0;
        &:checked {
          + .checked {
            opacity: 1;
          }
        }
      }
      &:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: ${val.GRAY};
        margin-top: 0;
        position: absolute;
      }
      .checked {
        transition: opacity .2s;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: ${val.BLACK};
        position: absolute;
        left: 6px;
        top: 1px;
        opacity: 0;
      }
      .text {
        display: inline-block;
        margin-left: 12px;
        ${media.sp`
          margin-left: 2vw;
        `}
      }
    }
  }
  .form-item {
    display: grid;
    grid-auto-columns: 2;
    grid-template-columns: 100px 1fr;
    margin-top: 30px;
    ${media.sp`
      grid-template-columns: 20vw 1fr;
      margin-top: 7vw;
    `}
    p {
      margin: 6px 0 0;
      ${media.sp`
        margin-top: .5vw;
        margin-left: 4vw;
      `}
    }
    input,
    textarea {
      resize: vertical;
      box-shadow: none;
      border: 1px solid ${val.BORDER};
      outline: 0;
      transition: background-color .2s;
      &:focus {
        background-color: ${val.FOCUS};
      }
    }
    input {
      height: 28px;
      line-height: 28px;
      width: 295px;
      ${media.sp`
        width: 70vw;
        line-height: 6vw;
        height: 6vw;
      `}
    }
    textarea {
      width: 293px;
      height: 205px;
      ${media.sp`
        width: 70vw;
        height: 50vw;
      `}
    }
  }
  .box-btn {
    text-align: center;
    margin-top: 60px;
    ${media.sp`
      margin-top: 6vw;
    `}
  }
`;
const Layer = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  background-color: rgba(0,0,0,.25);
  transition: opacity .3s ease-out;
  opacity: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  &.-active {
    opacity: 1;
  }
  &.-active-2 {
    height: 100%;
    width: 100%;
  }
  > .-content {
    background: #fff;
    display: inline-block;
    padding: 50px;
    position: absolute;
    ${media.sp`
      padding: 10vw;
    `}
  }
`;



export default () => {
  /*
  class MonsterView extends React.Component {
    constructor(props) {
      super(props);
      this.state ={
        isLoaded: false,
        error: null,
        monsters: []
      }
    }

    componentDidMount() {
      fetch('/contact-test.html')

        .then(
          (result) => {
            console.log(result);
            this.setState({
              isLoaded: true,

            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error: error
            });
          }
        )
    }

    render() {
      if (this.state.error) {
        return <div>Error: {this.state.error.message}</div>;
      }
      else if ( !this.state.isLoaded ) {
        return <div>Loading...</div>;
      }
      else {
        return (
          <ul>
            {this.state.isLoaded.map( monster => (
              <li key={monster.id}>
                {monster.name} {monster.hp}
              </li>
            ))}
          </ul>
        );
      }
    }
  }
  */

  const FormSubmit = e => {
    ReactDOM.render('sending...', document.querySelector('.js-layer-content'))
    document.querySelector('.js-layer').classList.add('-active')
    document.querySelector('.js-layer').classList.add('-active-2')
    const target = document.querySelector('.js-layer-content')
    const fetchForm = document.querySelector('#form-contact');
    target.innerHTML = 'sending...'
    let formData = new FormData(fetchForm);
    fetch('/contact.php',{
      method: 'POST',
      body: formData
    })
    .then(response => {
      if (!response.ok) {
        // console.log('Not ok')
      }
      return response.text()
    })
    .then(data => {
      target.innerHTML = data
    });
  }
  const ModalClase = e => {
  //  const $e = $(e.currentTarget);
    const target = e.currentTarget;
    target.classList.remove('-active')
    setTimeout(()=> {
      target.classList.remove('-active-2')
    }, 300);
  }
  return (
<Contact id="contact" className="section">
  <div className="-inner">
    <h2 className="title">CONTACT</h2>
    <div className="box">
      <p className="-p">お仕事のご依頼やご質問など、<br className="sp" />お気軽にお問い合わせください。</p>
      <Form id="form-contact" method="post">
        <div className="form-radio">
          <label>
            <input type="radio" name="ご用件" value="ご依頼" defaultChecked="true" />
              <span className="checked"></span>
              <span className="text">ご依頼</span>
          </label>
          <label>
            <input type="radio" name="ご用件" value="ご相談" />
            <span className="checked"></span>
            <span className="text">ご相談</span>
          </label>
          <label>
            <input type="radio" name="ご用件" value="その他" />
              <span className="checked"></span>
              <span className="text">その他</span>
          </label>
        </div>
        <div className="form-item">
          <p>お名前</p>
          <input type="text" name="お名前" />
        </div>
        <div className="form-item">
          <p>E-mail</p>
          <input type="email" name="Email" />
        </div>
        <div className="form-item">
          <p>内容</p>
          <textarea name="内容" cols="50" rows="5"></textarea>
        </div>
        <div className="box-btn">
          <ButtonSubmit className="btn btn-submit js-form-submit" type="button" onClick={FormSubmit}>
            <span className="text">送信</span>
            <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
          </ButtonSubmit>
        </div>
      </Form>
    </div>
  </div>
  <Layer className="js-layer" onClick={ModalClase}>
    <div className="-content js-layer-content">
      送信しました
    </div>
  </Layer>
</Contact>
)
}
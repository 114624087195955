import React from 'react'
import { Link, graphql  } from 'gatsby'
import styled from "styled-components"
import { val }  from '../components/cssvalue'
import {BlogArticleLink, BlogArticle} from '../pages/index.js'
import Tags from "../components/tags"
import {TagStyle} from '../templates/blog-post.js'
import { media } from '../components/style-utils';

// STYLE
export const TagStyleBlog = styled(TagStyle)`
`;
const ArticleInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.sp`
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: baseline;
  `}
  .-date {
    ${media.sp`
      font-size: 3vw;
    `}
  }
`;
// STYLE
const Articles= ({data, location, post, title, titileTag}) => {
post.frontmatter.tags.nolink = true
  return (
    <BlogArticleLink to={`/blog${post.fields.slug}`}>
      <BlogArticle key={post.fields.slug}>
        {titileTag === 'h2' ? <h2 className="-title">{title}</h2> : <h3 className="-title">{title}</h3>}
        <ArticleInfo>
          <TagStyleBlog>
            <Tags tags={post.frontmatter.tags} />
          </TagStyleBlog>
          <time className="-date">{post.frontmatter.date}</time>
        </ArticleInfo>
        <p className="-p"
          dangerouslySetInnerHTML={{
            __html: post.frontmatter.description || post.excerpt,
          }}
        />
      </BlogArticle>
    </BlogArticleLink>
  )
}
export default Articles

import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Contact from "../components/contact"
import Seo from "../components/seo"
import { val }  from '../components/cssvalue'
import Articles from "../components/articles"
import { media } from '../components/style-utils';


export const SectionArticle = styled.section`
  background: ${val.BG};
  > .-inner {
    max-width: ${val.width_pc};
    margin: 0 auto;
    padding: 45px 0 100px;
    ${media.sp`
      padding: 10vw 0 24vw;
    `}
    > .title {
      position: absolute;
      top: 58px;
      left: -6%;
      margin-left: -50px;
      border: 2px solid ${val.BG};
      ${media.sp`
        left: 0;
        top: 0;
        transform: translateY(0);
        margin-left: 0;
      `}
    }
  }
  .text {
     .-p {
       margin-top: 0;
       margin-bottom: 32px;
       letter-spacing: .1em;
       ${media.sp`
         margin-bottom: 8vw;
       `}
       &:first-child {
         margin-top: 14px;
         ${media.sp`
           margin-top: 3.7vw;
         `}
       }
     }
  }
`;
export const BlogArticleWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const BlogArticleLink= styled(Link)`
  color: ${val.BLACK};
  text-decoration: none;
  display: block;
  width: 49%;
  margin-top: 20px;
  background: ${val.WHITE};
  transition: background .2s;
  ${media.sp`
    margin-top: 5vw;
  `}
  &:hover {
    background: ${val.FOCUS};
  }
`;
export const BlogArticle = styled.article`
  padding: 20px;
  box-sizing: border-box;
  ${media.sp`
    margin-top: 5vw;
    padding: 0 4vw 5vw;
  `}
  .-title {
    ${media.sp`
      font-size: 3.6vw;
    `}
  }
`;
const MvSection = styled.div`
  position: relative;
  overflow: hidden;
  ${media.sp`
    height: 70vw;
  `}
  .mv-img-wrap {
    position: fixed;
    width: 100%;
    height: auto;
    top: 0;
    z-index: -1;
  }
`;
const  MvSectionText = styled.div`
  padding: 40px 0;
  color: ${val.WHITE};
  text-align: center;
  ${media.sp`
    padding: 10vw 0;
  `}
  .-title {
    text-shadow: 0 0 4px ${val.BLACK};
    font-size: 24px;
    ${media.sp`
      font-size: 4.7vw;
    `}
  }
  .-p {
    font-weight: 700;
    letter-spacing: .1em;
    text-shadow:
            2px 2px 3px #000, -2px -2px 3px #000,
           -2px 2px 3px #000,  2px -2px 3px #000,
            2px 0px 3px #000, -2px -0px 3px #000,
            0px 2px 3px #000,  0px -2px 3px #000;
    font-size: 16px;
    ${media.sp`
      font-size: 3.5vw;
    `}
  }
`;
const ImgMv = styled(Img)`
 position: relative;
 ${media.sp`
   height: 130vw;

 `}
`;
const  SectionSolutions = styled.section`
  background: ${val.BG3};
  &.section {
    > .-inner {
      padding-top: 40px;
      ${media.sp`
        padding-top: 10vw;
      `}
      > .title {
        position: absolute;
        top: 0;
        left: -12%;
        border: 2px solid ${val.BG};
      }
    }
    .-lead {
      margin: 0;
      font-size: 25px;
      ${media.sp`
        font-size: 5vw;
        padding-left: 14vw;
        padding-right: 2vw;
      `}
    }
`;
const  SectionExample = styled.section`
  background: ${val.BG3};
  padding-bottom: 60px;
  ${media.sp`
    padding-bottom: 12vw;
  `}
  &.section {
    > .-inner {
      > .title {
        position: absolute;
        top: 0;
        left: -12%;
        border: 2px solid ${val.BG};
      }
    }
    .-lead {
      margin: 0;
      font-size: 25px;
      ${media.sp`
        font-size: 5vw;
        padding-left: 12vw;
        padding-right: 2vw;
      `}
    }
  }
`;
const  SectionFeature = styled.section`
  background: ${val.WHITE};
  &.section {
    padding: 40px 0;
    > .-inner {
      > .title {
        background: ${val.BG3};
        position: absolute;
        top: 0;
        right: 0;
        border: 2px solid ${val.WHITE};
        ${media.sp`
          right: auto;
          left: 0;
          top: 0;
          transform: translateY(-16vw);
        `}
      }
    }
    .-lead {
      text-align: center;
      font-size: 25px;
      color: #72c169;
      margin: 0;
      ${media.sp`
        font-size: 4vw;
      `}
    }
  }
`;
export const PanelWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 60px 0;
  ${media.sp`
    padding-bottom: 14vw;
  `}
  > section {
    background: ${val.WHITE};
    width: 32%;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 14px;
    &.-full {
      width: 100%;
    }
    &.-wide {
      width: 66%;
      ${media.sp`
        width: 91%;
      `}
    }
    &.-mid {
      width: 49%;
      ${media.sp`
        width: 91%;
      `}
    }
    ${media.sp`
      width: 91%;
      margin-left: auto;
      margin-right: auto;
      }
    `}
  }
  > .-inner {
    ${media.sp`
      margin: 0 5vw;
    `}
  }
`;
const TypeSpecial= styled.div`
  text-align: center;
  ${media.sp`
    text-align: left;
    margin-left: 4vw;
    margin-right: 4vw;
    font-size: 3.4vw;
  `}
  p {
    margin: 0 0 10px;
  }
`;
const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo
          title="格安で大手WEB制作会社のクオリティ 神奈川のホームページ製作デザインTAN-ZENT｜横須賀 葉山 逗子 三浦"
          description="20年以上のキャリアをもつベテランが対応する神奈川・三浦半島のフリーランスWEB制作事務所 　神奈川 東京 横須賀 鎌倉 葉山 逗子 三浦 藤沢 湘南 横浜"
          />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }
  return (
    <Layout location={location} title={siteTitle}>
        <Seo
          title="格安で大手WEB制作会社のクオリティ TAN-ZENT 神奈川のホームページ製作所 作成 運用 デザイン｜神奈川 横浜 横須賀 鎌倉 葉山 逗子 三浦 藤沢"
          description="20年以上のキャリアをもつベテランが対応する神奈川・三浦半島のフリーランスWEB制作事務所 　神奈川 東京 横須賀 鎌倉 葉山 逗子 三浦 藤沢 湘南 横浜"
          type="index"
        />
      <MvSection id="mv">
        <div className="mv-img-wrap">
          <ImgMv fluid={data.mv.childImageSharp.fluid} alt="" />
        </div>
        <MvSectionText>
          <h1 className="-title">神奈川でホームページのことなら<br className="sp" />TAN-ZENT（たんぜん）にお任せください</h1>
          <p className="-p">キャリア20年以上の専門家によるクオリティ<br />個人事務所ならではの安さと柔軟性<br />ホームページの作成から運用までお任せください</p>
        </MvSectionText>
      </MvSection>
      <SectionSolutions className="section" id="solutions">
        <div className="-inner">
          <p className="title">SOLUTIONS</p>
          <div className="box">
            <h2 class="-lead">
              <strong>神奈川</strong>で<strong>格安・高クオリティー</strong>なホームページ作成 ほかサービス内容
            </h2>
            <PanelWrap>
            <section className="-wide">
              <h3>ホームページ作成</h3>
              <p class="-p">
                会社や団体のホームページ、お店のホームページ、ポートフォリオ、メディアサイトなどを作成します。<strong>外部サービスの選定・設定代行</strong>から、有名ウェブメディアや有名企業のホームページのような<strong>大規模サイトを作成できるプラン</strong>まで、わかりやすい<strong>機能と価格からプランを選ぶ</strong>ことができます。
                また、ご予算と目的を教えていただければ、プランに関わらずご満足いただけるホームページを作成いたします。
              </p>
              <p class="-btnbox">
                <Link to="/web/" className="btn en btn-arrow"  activeClassName="active">
                  <span className="text">ホームページ作成 プランと価格 詳細</span>
                  <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
                </Link>
              </p>
            </section>
            <section>
              <h3>ネットショップ作成</h3>
              <p class="-p">
                外部サービスを利用したお手軽でシンプルなネットショップから、本格的な大規模自社ECサイトまで作成できます。ECサイトと記事メディアが融合したメディアコマースサイトもお任せください。
              </p>
            </section>
            <section>
              <h3>ランディングページ作成</h3>
              <p class="-p">
              キャンペーンやブランド特設ページ、集客に特化したページの作成をおこないます。急ぎでお手軽に作るランディングページから、戦略を立て公開後に成長させるランディングページまで、すべてお任せください。
              </p>
            </section>
            <section className="-wide">
              <h3>ホームページの運用・改善・SEO</h3>
              <p class="-p">
                ホームページは作成だけではなく運用も重要です。個人ブログから1億PVを超える有名サイトまで、豊富な運用経験から修正や改善をおこない、より成果を出せるホームページへと成長させていきます。SEO対策やSNSマーケティングによる集客もお任せください。
              </p>
              <p class="-btnbox">
                <Link to="/operate/" className="btn en btn-arrow"  activeClassName="active">
                  <span className="text">ホームページ運用 詳細</span>
                  <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
                </Link>
              </p>
            </section>
            <section className="-mid">
              <h3>デザイン</h3>
              <p class="-p">
                ウェブデザインだけではなく、ロゴのデザイン、名刺やチラシ、パンフレット、会社案内、小冊子など、あなたのビジネスや活動にかかわるデザイン全般ご相談ください。デザインの力でブランド力をアップし、商品や企業のイメージアップを達成できます。
              </p>
              <p class="-btnbox">
                <Link to="/design/" className="btn en btn-arrow"  activeClassName="active">
                  <span className="text">デザイン 詳細</span>
                  <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
                </Link>
              </p>
            </section>
            <section className="-mid">
              <h3>コンサルティング</h3>
              <p class="-p">
                メールアドレスが欲しいといった基本的なことから、業務のデジタル化まで、ITコンサル全般お任せください。初めてネットショップを始めたい、フリーランスとして独立したい、会社やNPO団体を立ち上げたい、などもご相談ください。
              </p>
            </section>
            </PanelWrap>
          </div>
        </div>
      </SectionSolutions>
      <SectionExample className="section" id="example">
        <div className="-inner">
          <p className="title">EXAMPLE</p>
          <div className="box">
            <h2 class="-lead">
              このような皆さまにちょうどいいホームページ作成プランがあります
            </h2>
            <PanelWrap>
              <section className="-mid">
                <h3>会社・団体・学校・公的機関</h3>
                <p class="-p">
                  <strong>中小企業、大企業、NPO団体、各種法人、教育機関、公的機関など</strong><br />
                  信頼される企業や団体の顔として、会社案内、事業案内や集客、求人、企業イメージ、ブランドイメージ向上のツールとして、ホームページはとても重要です。<strong>ご予算や目的に応じた最適なホームページや企業案内を作成</strong>します。
                </p>
              </section>
              <section className="-mid">
                <h3>お店・施設・工場・病院</h3>
                <p class="-p">
                  <strong>飲食店、小売店、美容室、洋服店、雑貨店、花屋、不動産屋、植木屋、工務店、自動車修理工場、旅行代理店、ペットクリニック、整体、福祉施設、病院、クリニックなど</strong><br />
                  集客やブランドイメージが重要な業態にとって、ホームページは第2の店舗ともいえます。伝えたいこととお客様が知りたいことのバランスが取れたホームページを作成します。
                </p>
              </section>
              <section className="-mid">
                <h3>事務所・士業・各種教室・寺社仏閣・観光・旅行レジャー</h3>
                <p class="-p">
                  <strong>弁護士、税理士、会計事務所、建築士、司法書士、行政書士、英会話スクール、観光案内所、カウンセリング、寺院、庭園、植物園、観光地、遊園地、美術館、アートギャラリー、各種事務所・事業所、各種インストラクターなど</strong><br />
                  独特なデザインやイメージが必要となるホームページが必要でも、キャリア20年以上のアートディレクター・デザイナーが対応いたします。それぞれの業態と目的に応じたホームページを作成いたします。
                </p>
              </section>
              <section className="-mid">
                <h3>フリーランス・専門家・小さな団体</h3>
                <p class="-p">
                  <strong>学者、研究者、芸能人、文筆家、音楽家、政治家、アーティスト、各種フリーランス、自由業、サークル、バンド、グループなど</strong><br />
                  個人や数人で活動するための名刺代わりやパンフレットともなるポートフォリオサイトを作成します。イベントや個展の告知・予約、メンバー募集をするようなホームページの作成も可能です。作品を販売できるネットショップの作成もご相談ください。
                </p>
              </section>
            </PanelWrap>
            <TypeSpecial>
              <p>
                <b>下記に該当するお客様には<strong>特別優待（5％〜25％割引）</strong>をおこなっています</b><br />
                自然関連（鳥獣保護、庭園、庭師、動物病院、農園など）<br />
                美術、本、福祉関連、寺社仏閣、三浦半島にあるお店など
              </p>
              <p>
                <Link to="/blog/incentives/" className="btn en btn-arrow"  activeClassName="active">
                  <span className="text">優待サービス詳細</span>
                  <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
                </Link>
              </p>
            </TypeSpecial>
          </div>
        </div>
      </SectionExample>
      <SectionFeature className="section" id="feature">
        <div className="-inner">
          <h2 className="title">TAN-ZENTの特徴・メリット</h2>
          <div className="box">
            <h3 class="-lead">
              神奈川のホームページ作成ならTAN-ZENT<br />
              大手のクオリティを個人の格安価格で実現<br />
              価値あるホームページ・デザインを提供します
            </h3>
            <PanelWrap>
              <section>
                <h3>対応エリア</h3>
                <p class="-p">
                  <strong>神奈川県全域</strong>、<strong>東京都23区</strong>のほか、フルリモートによる対応でよろしければ<strong>全国対応</strong>いたします。<br />TAN-ZENTの拠点は三浦半島の<strong>葉山横須賀エリア</strong>です。
                </p>
              </section>
              <section className="-wide">
                <h3>柔軟性</h3>
                <p class="-p">
                個人事務所ならではの柔軟性。<br />
                例えば弊所の<strong>ホームページ作成は55,000円〜</strong>となっていますが、現在は他社様の無料ホームページサービスも多々ございます。お客様のご予算や目的によっては、そういった外部サービスのご紹介など、<strong>さらに低価格でホームページを手に入れるお手伝い</strong>もできます。<br />
                営業時間は平日の9:00〜18:00を基本としていますが、事前にご相談いただければ早朝対応や休日・年末年始の稼働なども、状況次第で可能となります。
                </p>
              </section>
              <section className="-mid">
                <h3>価格とクオリティ</h3>
                <p class="-p">
                現在でも<strong>大手のWEB作成を手がけるスタッフが対応するクオリティーでありながら、個人事務所の低価格さでホームページ作成などのサービスを提供</strong>できるのが一番のメリットです。<br />
                大手のWEB制作は一般的に、<b>クライアント→大手広告代理店→中堅WEB制作会社→小規模なWEB制作会社→フリーランス</b>といったように中間マージン費用が多くかかりますが、<strong>実際に制作をしているのは小規模なWEB制作会社やフリーランス</strong>ということが多いです。<br />
                TAN-ZENTにご依頼いただければ、<b>クライアント（お客様）→弊所</b>と直接取引となるため<strong>中間マージンがかかりません</strong>。一概にはいえませんが、2倍〜5倍くらいの価格差はあります。<br />また作成する者が直接お客様と話し合えますので、お客様のイメージややりたいこと、目的を成果物に反映しやすいのもメリットのひとつです。<br />
                行政書士となる資格をもつ法に詳しくスタッフもおりますので、<strong>取引に関する契約やお約束についても法的に明確にできて安心</strong>です。<br />逆にデメリットとして、ご相談をいただいたときに仕事が埋まっていると、通常よりお時間をいただいたり対応ができない場合もございます。
                </p>
              </section>
              <section className="-mid">
                <h3>経歴</h3>
                <p class="-p">
                <strong>デザイン経験20年以上</strong>の<b>アートディレクター兼デザイナー</b>と、<strong>ウェブ作成経験20年以上</strong>の<b>フルスタックエンジニア兼テクニカルディレクター兼ウェブディレクター</b>のウェブ作成事務所「TAN-ZENT（たんぜん）」は「鳥獣花木苑」のWEB部門です。<br />
                大手広告作成会社やWeb作成会社などで経験を積み2011年に独立。大企業や有名ブランドのデザインとWEB制作、誰もが知っている月間PV億越え巨大サイトの運用まで、中〜大規模な案件を中心に活動してきました。独立後は個人ブログや小さなネットショップ、NPO法人のホームページなど小規模なホームページの経験も多く積み、2014年にはMdN社のHTML5デザイン関連の書籍執筆もいたしました。2018年に東京港区渋谷区エリアから神奈川葉山横須賀エリアへと拠点を移し現在にいたります。
                </p>
              </section>
            </PanelWrap>
          </div>
        </div>
      </SectionFeature>
      <SectionArticle id="article" className="section">
        <div className="-inner">
          <h2 className="title">BLOG & INFO</h2>
          <div className="box">
            <BlogArticleWrap>
              {posts.map((post, i) => {
                if(i >= 4) {
                  return;
                }
                const title = post.frontmatter.title || post.fields.slug
                return (
                  <Articles
                    post={post}
                    title={title}
                   />
              )
            })}
            </BlogArticleWrap>
            <div style={{textAlign:"center",marginTop:"40px"}}>
              <Link to="/blog/" className="btn en btn-arrow"  activeClassName="active">
                <span className="text">ブログ 記事一覧</span>
                <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
              </Link>
            </div>
          </div>
        </div>
      </SectionArticle>
      <Contact />
    </Layout>
  )
}
export default BlogIndex
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt(pruneLength: 120)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          description
          tags
        }
      }
    }
    mv: file(relativePath: {eq: "mv.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
